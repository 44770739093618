body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* TODO: Right now before a browser has these downloaded, it displays nothing on the screen.
* Let's get a fallback or something for these fonts.*/
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: 'Exo 2', sans-serif;
  font-weight: 800;
  color: #cccccc;
}

body {
  background-color: black;
}

#main-wrapper {
  text-align: center;
  box-sizing: border-box;
}

#lower-pane-wrapper > div {
  display: inline-block;
  vertical-align: top;
}

.index-pane {
  width: 45%;
  height: 100%;
}

button.button {
  border: 3px solid #111;
  filter: invert(0.1);
}

.button {
  cursor: pointer;
  display: block;
  margin: 20px;
  padding: 40px 20px 40px 20px;
  width: 80%;
  background-color: #131313;
  text-align: center;
  font-size: 18pt;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  transition: all 0.3s;
}

.button:hover {
  box-shadow: inset 0px 0px 20px 10px #2f2f2f;
  color: white;
}

a {
  text-decoration: none;
}

footer {
  margin-top: 3%;
  display: block;
  text-align: center;
  color: #555555;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  background: #2f2f2f;
}

::-webkit-scrollbar-track-piece {
  background: #131313;
}

#session-request {
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  width: 15%;
  height: 10%;
  background-color: #131313;
  text-align: center;
  border-radius: 10px;
  padding: 10%;
  min-width: 300px;
}

#session-request label {
  font-size: 18pt;
}

#session-request p {
  font-size: 14pt;
}

#session-request code {
  font-size: 12pt;
}

#session-request-input {
  border-radius: 10px;
  padding: 10px;
  font-size: 15pt;
  margin-left: -25px;
  transition: all 0.3s;
  color: black;
  text-align: center;
}

#session-request-input:focus {
  box-shadow: inset 0 0 15px 2px #2f2f2f;
}

#canvas-wrapper {
  text-align: center;
  margin-top: 1px;
}

canvas {
  transition: all 0.1s;
}

#restart-screen-wrapper {
  position: absolute;
  top: 30%;
  width: 100%;
}

#restart-screen {
  width: 30%;
}

#restart-screen:hover {
  box-shadow: inset 0px 0px 20px 10px red;
  color: red;
}

#footer-container {
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translateX(-50%);
}
